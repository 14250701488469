export default [
  {
    // [0]
    key: "merchant",
    label: "Merchant",
    tdClass: "pt-1",
    visible: true,
  },
  {
    // [1]
    key: "transactions_succesfully",
    label: "Transaction Successfully",
    tdClass: "pt-1 text-success",
    visible: true,
  },
  {
    // [2]
    key: "transactions_error",
    label: "Transaction Error",
    tdClass: "pt-1 text-danger",
    visible: true,
  },
  {
    // [3]
    key: "captured_pending",
    label: "Captured/Pending",
    tdClass: "pt-1 text-warning",
    visible: true,
  },
  {
    // [4]
    key: "authorized_pending",
    label: "Authorized/Pending",
    tdClass: "pt-1 text-warning-50",
    visible: true,
  },
  {
    // [5]
    key: "user_name",
    label: "User",
    tdClass: "pt-1",
    visible: true,
  },
  {
    // [6]
    key: "created_at",
    label: "Date",
    tdClass: "pt-1",
    visible: true,
  },
  {
    // [7]
    key: "actions",
    label: "Actions",
    tdClass: "pt-1",
    visible: true,
  },
];
