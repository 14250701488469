<template>
  <div>
    <b-card no-body class="mb-0 border-0 p-0">
      <filter-slot
        :noVisiblePrincipalFilter="true"
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refHistoryImports'].refresh()"
        @reset-all-filters="refreshTable"
      >
        <b-table
          slot="table"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          small
          sort-icon-left
          ref="refHistoryImports"
          :items="myProvider"
          :fields="filteredFields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          show-empty
          sticky-header="68vh"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_at | myGlobalDay }}
          </template>
          <template #cell(actions)="data">
            <b-button
              size="sm"
              variant="primary"
              @click="openHistoryImportDetails(data.item.id)"
            >
              <span> Detail </span>
              <feather-icon icon="ChevronRightIcon" />
            </b-button>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <history-import-detail
      v-if="historyImportDetailOpened"
      @hidden="closeHistoryImportDetails"
      :history-import-id="selectedHistoryImportId"
    />
  </div>
</template>

<script>
import fields from "@/views/administration/views/register-payment/data/history-imports.fields";
import filters from "@/views/administration/views/register-payment/data/history-imports.filters";
import RegisterPaymentService from "@/views/administration/views/register-payment/register-payment.service";
import HistoryImportDetail from "@/views/administration/views/register-payment/components/HistoryImportDetail.vue";
import GlobalService from "@/service/global";
export default {
  components: {
    HistoryImportDetail,
  },
  mounted() {
    this.getAdministrators();
  },
  computed: {
    filteredFields() {
      return this.fields.filter((item) => item.visible);
    },
  },
  data() {
    return {
      filters,
      fields,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      historyImportDetailOpened: false,
      selectedHistoryImportId: null,
    };
  },
  methods: {
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
    openHistoryImportDetails(historyImportId) {
      this.selectedHistoryImportId = historyImportId;
      this.historyImportDetailOpened = true;
    },
    closeHistoryImportDetails() {
      this.selectedHistoryImportId = null;
      this.historyImportDetailOpened = false;
    },
    async myProvider(ctx) {
      try {
        const params = {
          from: this.filters[0].model,
          to: this.filters[1].model,
          page: ctx.currentPage,
          user: this.filters[2].model,
        };
        const { data } = await RegisterPaymentService.getHistoryImports(params);
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        return data.data || [];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getAdministrators() {
      try {
        const { data: administrators } = await GlobalService.getOwners({
          modul: 4,
          body: {
            roles: "[1,2]",
            type: "1",
          },
        });
        this.filters[2].options = [
          ...this.filters[2].options,
          ...administrators,
        ];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    refreshTable() {
      this.$refs.refHistoryImports.refresh();
    },
  },
};
</script>

<style>
</style>