<template>
  <b-modal
    title="History Import Detail"
    ref="history-import-details"
    @hidden="$emit('hidden')"
    size="xl"
    hide-footer
  >
    <div class="breadcrumb-wrapper mb-1 ml-0">
      <b-breadcrumb>
        <b-breadcrumb-item>
          <b-button variant="info" size="sm">Merchant: {{ merchant }}</b-button>
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          <b-button variant="info" size="sm">User: {{ user }}</b-button>
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          <b-button variant="info" size="sm"
            >Transaction S: {{ transactionsS }}</b-button
          >
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          <b-button variant="info" size="sm"
            >Transaction E: {{ transactionsE }}</b-button
          >
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          <b-button variant="info" size="sm"
            >Date: {{ date | myGlobalDay }}</b-button
          >
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <b-table
      :fields="fields"
      :items="details"
      small
      class="position-relative font-small-3"
      primary-key="id"
      empty-text="No matching records found"
      select-mode="multi"
      responsive="sm"
      table-class="text-nowrap"
      show-empty
      sticky-header="68vh"
    >
      <template #cell(settlement_date)="data">
        {{ data.item.settlement_date | myGlobalDay }}
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import RegisterPaymentService from "@/views/administration/views/register-payment/register-payment.service.js";
export default {
  props: {
    historyImportId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      details: [],
      fields: [
        {
          key: "transaction_id",
          label: "Transaction",
          visible: true,
        },
        {
          key: "name_file",
          label: "Client Excel",
          visible: true,
        },
        {
          key: "name_bd",
          label: "Client A.M.G",
          visible: true,
        },
        {
          key: "account",
          label: "Account",
          visible: true,
        },
        {
          key: "program",
          label: "Program",
          visible: true,
        },
        {
          key: "advisor_name",
          label: "Advisor",
          visible: true,
        },
        {
          key: "amount",
          label: "Amount",
          formatter: (value) => `$ ${value}`,
          visible: true,
        },
        {
          key: "card_number",
          label: "Credit Card",
          visible: true,
        },
        {
          key: "status",
          label: "Transaction Status",
          visible: true,
        },
        {
          key: "settlement_date",
          label: "Settlement Date/Time",
          visible: true,
        },
      ],
    };
  },
  mounted() {
    this.toggleModal("history-import-details");
    this.getHistoryImportDetail();
  },
  computed: {
    merchant() {
      return this.details[0]?.merchant == 1 ? "AMG" : "WWCE";
    },
    user() {
      return this.details[0]?.user_name;
    },
    transactionsS() {
      return this.details[0]?.transactions_succesfully;
    },
    transactionsE() {
      return this.details[0]?.transactions_error;
    },
    date() {
      return this.details[0]?.created_at;
    },
  },
  methods: {
    async getHistoryImportDetail() {
      try {
        const { data: details } =
          await RegisterPaymentService.getHistoryImportDetail({
            id: this.historyImportId,
          });
        this.details = details;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style>
</style>